<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <img
      src="https://storage.googleapis.com/komerce/assets/logo/logo-text/Logo-Komerce-enabler.svg"
      alt="komerce"
      width="144"
      height="48"
    >
    <div class="misc-content">
      <img
        :src="image"
        alt="404"
        width="377"
        height="192"
        :class="{'mb-[32px]': marginAfterImage}"
      >
      <strong class="mb-[16px] font-weight-bold text-[#333333] text-center">{{ title }}</strong>
      <p class="mb-[48px] text-[#828282] text-center">
        {{ desc }}
      </p>
      <b-button
        variant="primary"
        class="btn-sm-block"
        @click="$emit('onAction')"
      >
        {{ btnLabel }}
      </b-button>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: 'https://storage.googleapis.com/komerce/komerce-v1.0/illust-404.svg',
    },
    title: {
      type: String,
      default: 'Oops, halaman yang kamu cari nggak ketemu!',
    },
    desc: {
      type: String,
      default: 'Jangan khawatir, Yuk, balik ke halaman utama untuk lanjut eksplorasi!',
    },
    btnLabel: {
      type: String,
      default: 'Halaman Utama',
    },
    marginAfterImage: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
