<template>
  <ErrorTemplate
    image="https://storage.googleapis.com/komerce/komerce-v1.0/illust-expired.svg"
    title="Sesi kamu sudah habis!"
    desc="Yuk, login lagi dan lanjutkan aktivitasmu kembali!"
    btn-label="Login"
    @onAction="onAction"
  />
</template>

<script>
import secureLs from '@/libs/secureLs'
import secureCookies from '@/libs/cookies'
import ErrorTemplate from './ErrorTemplate.vue'

export default {
  components: { ErrorTemplate },
  methods: {
    onAction() {
      window.location.replace('/')
      secureLs.clearItem()
      secureCookies.removeItem('accessToken')
    },
  },
}
</script>
